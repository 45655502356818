<template>
  <div style="text-align: center;line-height: 55px;font-size: 22px;">
    <router-link to='/' class="header_logo" style="">
      <img src="https://data.chinaecono.com/images/logo.png?t=1683356233" height="35" >
      <span>经济运行平台</span>
    </router-link>
    <span>{{title}}</span>
  </div>
  <iframe :src='`/static/pdf/web/viewer.html?file=https://data.chinaecono.com/pdf${path}&page=1`' style="width:100%;" :style="{'height':(windowHeight-55-4)+'px'}"></iframe>
</template>
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name:'Pdf',
  setup() {
    let route =useRoute();
    const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
    console.log(windowHeight);
    console.log(route);
    let path = route.query.path;
    let title=path.substring(path.lastIndexOf('\\')+1,path.lastIndexOf('.pdf'));
    console.log(title);
    return{
      windowHeight,
      path,
      title
    }
  },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.header_logo{
  position: absolute;
  left: 10px;
  img{
    vertical-align: top;
    margin-top: 10px;
  }
  span{
    color: $specialtext-color;
    font-size: 22px;
    line-height: 35px;
    border-left: 1px solid $specialtext-color;
    margin-left: 10px;
    padding-left: 10px;
  }
  
}
</style>